.cc-theme-edgeless.cc-window {
  padding: 10px !important;
  font-size: 15px !important;
}

.cc-message {
  .text-link {
    color: #000;

    &:hover {
      text-decoration: underline;
    }
  }
}

.cc-deny {
  font-weight: normal !important;
  text-transform: uppercase !important;
}

.cc-dismiss {
  text-transform: uppercase !important;
}