.text-list {
  >.items {
    .row;
    // overflow: hidden;

    >.item {
      .make-sm-column(6);
      margin-bottom: 26px;
      font-size: 16px;
      line-height: 22px;

      >.inner-wrap {
        padding-bottom: 26px;
        border-bottom: solid 1px @gray;

        >.headline {
          margin: 0;
          font-size: 16px;
          line-height: 22px;
          color: @brand-primary;
        }// >.headline

        >.description {
          min-height: 44px;
        }

       }// >.inner-wrap


    }// >.item


  }// >.items
}// >.text-list
