.ce-media {
  .aspect-ratio(854, 480);
  margin-top: 64px;
  margin-bottom: 64px;  
  position:relative;
  overflow:hidden;
  width: 100%;

  >iframe {
    position:absolute;
  	top:0;
  	left:0;
  	width:100%;
  	height:100%;
  }
}
