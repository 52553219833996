.header {
  // position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  #sm-min({
    position: fixed;
  });

  >.header-part-1 {
    z-index: 100;
    height: 75px;
    @media (min-width: @screen-sm-min) { height: 135px; }
    width: 100%;
    background: #fff;

    >.inner-wrap {
      .container;
      position: relative;
    }// >.inner-wrap
  }// >.header-part-1

  >.header-part-2 {
    z-index: 100;
    width: 100%;
    background-color: fade(@gray-darker, 70%);

    @media (min-width: @screen-sm-min) {
      display: block;
      height: 46px;
      background-color: fade(@gray-darker, 50%);

      >.inner-wrap {
        .container;
      }// >.inner-wrap
    }
  }// >.header-part-2

  .service-link {
    .hidden-xs;
    // display: none;
    position: absolute;
    right: 195px;
    top: 10px;
    padding: 0;
    margin: 0 -6px;
    list-style: none;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;

    > a {
      >.icon {
        display: inline-block;
        width: 20px;
        height: 15px;
        margin-right: 6px;
        background-image: url(../Images/contact-icon.svg);
        background-repeat: no-repeat;
        background-size: 18px 15px;
        opacity: 0.6;
      } // >.icon

      color: @gray;
      transition: color 200ms;

      &:hover {
        color: @brand-primary;
        text-decoration: none;
      }
    }
  }// .service-link

  .seal {
    .hidden-xs;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 100px;
    height: 100px;
    background: url(../Images/9001_ger_tc_p.png) center no-repeat;
    background-size: contain;
  }

}// .header
