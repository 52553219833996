.two-columns-text {
  margin: 40px 0;
  padding-bottom: 40px;
  border-bottom: solid 1px @gray-light;

  >.inner-wrap {
    font-size: 16px;
    line-height: 22px;

    >.headline {
      margin: 0;
      font-size: 16px;
      font-weight: 700;
      color: @brand-primary;
    }

    >.text-wrap {
      #sm-min({
        column-count: 2;
        column-gap: 30px;
      });
    }
    
  }
}
