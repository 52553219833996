.content-teaser {
  padding-top: 15px;
  padding-bottom: 15px;
  color: #fff;

  #sm-min({ padding-top: 30px; padding-bottom: 60px; });

  > .inner-wrap {

    > .items {
      .row;
//      .make-sm-column(4);
    }
  }
}

.content-teaser-item {
  .make-sm-column(4);

  #xs({ margin-top: 15px; });

  > .inner-wrap {
    position: relative;
    background: #000;
    overflow: hidden;

    a {
      text-decoration: none;
    }

    &:hover {
      .image {
        transform: scale(1);
        filter: none;
        opacity: 1;
      }

      h3 {
      }
    }

    h3 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      font-size: 30px;
      color: #fff;
      transition: all 200ms;
      text-align: center;
    }

    .image {
      width: 100%;
      height: auto;
      transform: scale(1.05);
      opacity: 0.95;
      filter: blur(4px);
      transition: all 200ms;
    }
  }
}
