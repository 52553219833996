.header .logo {
  position: absolute;
  left: 13px;
  top: 15px;
  .size(144px, 40px);
  margin: 0;

  >a {
    display: block;
    .square(100%);
    overflow: hidden;
    text-indent: -9999px;
    background-size: 144px 40px;
    background-image: url(../Images/logo.svg);
  }// >a

  @media (min-width: @screen-sm-min) {
    top: 32px;
    .size(255px, 71px);

    >a {
      background-size: 200px 71px;
      background-repeat: no-repeat;
    }
  }// @media (min-width: @screen-sm-min)

}// .header .logo
