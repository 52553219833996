.home-news {
  .container;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #fff;

  #sm-min({
    padding-top: 30px;
    padding-bottom: 40px;
  });
  #md-min({
    padding-top: 80px;
    padding-bottom: 80px;
  });

  h4 {
    position: relative;
    z-index: 1;
    margin: 0 0 20px 0;
    font-size: 16px;
    line-height: 26px;
  }

  >.inner-wrap {
    .row;

    >.infos-column {
      .make-sm-column(4);

      >.inner-wrap {
        height: 490px;
        padding: 15px;
        background: @brand-primary;

        #lg({
          padding: 28px;
        });

      }// >.inner-wrap
    }// >.infos-column

    >.map-column {
      .make-sm-column(8);

      >.inner-wrap {
        position: relative;
        height: 300px;
        padding: 15px;
        background: #a9a9a9;

        #sm-min({
          height: 490px;
        });

        #lg({
          padding: 28px;
        });
      }// >.inner-wrap
    }// >.map-column
  }// >.inner-wrap


  // Infos Slider:
  .infos-slider {
    position: relative;
    height: 400px;

    >.carousel {
      position: relative;
      height: 100%;

      >.controls {
        position: absolute;
        bottom: 0;
        width: 100%;

        >.inner-wrap >.carousel-indicators {
          bottom: -20px;

          #sm-min({
            bottom: 0;
          });
        }
      }// >.controls

      >.carousel-inner {
        >.item {
          >.text {
            font-size: 20px;
            line-height: 26px;
            font-weight: 300;

            #sm({
              font-size: 16px;
              line-height: 22px;
            });

            .headline {
              margin: 0 0 25px 0;
              font-size: 26px;
              line-height: 30px;
              font-weight: 500;

              #sm({
                font-size: 22px;
                line-height: 24px;
              });
            }

            >p {
              margin: 0;
            }
          }// >.text

          >a {
            display: inline-block;
            width: auto;
            margin-top: 25px;
            padding: 10px;
            color: #fff;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            background: fade(#000, 20);
            transition: background-color 200ms;

            &:hover {
              background: fade(#000, 40);
            }
          }

        }// >.item
      }// >.carousel-inner
    }// >.carousel
  }// .infos-slider

  .news-column {
    >.news {
      .make-sm-column(4);

      #xs({
        margin-top: 15px;
      });

      >.inner-wrap {
        position: relative;
        background: #e4e4e4;

        #xs({
          padding-bottom: 30px;
        });

        #sm-min({
          height: 490px;
        });

        >.subject {
          position: absolute;
          top: 24px;
          left: 30px;
          margin: 0;
          font-size: 16px;
          color: #fff;
        }// >.subject

        >.date {
          position: absolute;
          top: 0;
          right: 0;
          margin: 0;
          padding: 8px 16px;
          font-size: 12px;
          text-transform: uppercase;
          color: #fff;
          background: @brand-primary;
        }// >.date

        >.image {
          width: 100%;
          height: auto;
        }// >.image

        >.text {
          padding: 30px 30px 20px 30px;
          font-size: 20px;
          line-height: 26px;
          font-weight: 300;
          color: #000;

          #sm({
            font-size: 16px;
            line-height: 22px;
          });

          >p {
            margin: 0;
          }
        }// >.text

       >a {
         display: inline-block;
         width: auto;
         margin: 0 0 0 30px;
         padding: 10px;
         color: #fff;
         font-size: 14px;
         font-weight: 700;
         text-transform: uppercase;
         background: fade(#000, 20);
         transition: background-color 200ms;

         &:hover {
           background: fade(#000, 40);
         }
       }

      }// >.inner-wrap
    }// >.news
  }// .news-column

}
