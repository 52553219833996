.downloads {
  >.inner-wrap {
    >.headline {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 500;
    }// >.headline

    >.items {
      >.item {
        .row;
        margin: 0 0 8px 0;
        background: #fff;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;

        &:last-child {
          margin-bottom: 0;
        }

        >.title {
          .make-xs-column(6);
          .make-sm-column(4);
          .make-md-column(5);
          padding: 12px 25px;
          font-size: 16px;

          #sm-min({
            padding: 25px;
          });

        }// >.title
        >.size {
          .make-xs-column(6);
          .make-sm-column(3);
          .make-md-column(2);
          padding: 12px 25px;
          text-align: right;

          #sm-min({
            padding: 25px;
            text-align: left;
          });

        }// >.size

        >.link-wrap {
          .make-xs-column(12);
          .make-sm-column(5);
          .make-md-column(5);
          padding: 0 25px;

          #sm-min({
            text-align: right;
          });

          >a {
            display: block;
            padding: 25px 0 25px 50px;
            text-transform: uppercase;
            color: @gray-darker;
            background: url(../Images/pdf-icon.svg) no-repeat left 15px;
            background-size: 30px 39px;
            transition: color 200ms;

            #sm-min({
              padding: 25px 50px 25px 0;
              background-position: right 15px;
            });

            &:hover {
              color: #000;
            }
          }

        }// >.link-wrap


      }// >.item

    }// >.items

  }// >.inner-wrap
}// .downloads

.subpage-with-navigation .downloads {
  >.inner-wrap {
    >.items {
      >.item {
        >.title {
          .make-sm-column(12);
          .make-md-column(12);
          padding: 25px 15px 0 15px;
        }
        >.size {
          .make-sm-column(12);
          .make-md-column(6);
        }
        >.link-wrap {
          .make-sm-column(12);
          .make-md-column(6);
        }
      }
    }
  }
}
