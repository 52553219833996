.header .navigation-level-1 {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 0;
  text-align: right;

  >li {
    display: inline-block;
    width: 100%;
    height: 46px;
    padding: 0;

    > a {
      display: block;
      height: 46px;
      padding-top: 12px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      transition: background-color 200ms;

      &:hover {
        color: #fff;
        text-decoration: none;
        background-color: @gray-darker;
      }
    }

    &.active a {
      background-color: @brand-primary;
    }
  }// >li

  @media (min-width: @screen-sm-min) {
    >li {
      width: auto;

       > a {
         padding: 12px 20px 0 20px;
         font-size: 14px;
       }
    }// >li
  }// @media (min-width: @screen-sm-min)

  @media (min-width: @screen-md-min) {
    >li {

       > a {
         padding: 12px 22px 0 22px;
         font-size: 16px;
       }
    }// >li
  }// @media (min-width: @screen-md-min)

  @media (min-width: @screen-lg-min) {
    >li {

      > a {
        padding: 12px 30px 0 30px;
        font-size: 16px;
      }
    }// >li
  }// @media (min-width: @screen-md-min)

}// .header .navigation-level-1
