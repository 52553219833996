.footer {
  width: 100%;
  background-color: @brand-secondary;
  color: #fff;

  h3 {
    margin: 30px 0 22px 0;
    font-size: 20px;
    font-weight: 300;
    color: #fff;

    #sm-min({
      margin-top: 30px;
      margin-bottom: 20px;
    }); // #sm-min

  }

  a {
    color: #fff;
    font-weight: 500;
    transition: color 200ms;

    &:hover {
      color: fade(#fff, 80%);
    }
  }

  >.footer-part-1 {
    padding-bottom: 30px;

    >.inner-wrap {
      .container;

      >.columns {
        .row;

        >.column-1 {
          .make-sm-column(3);
        }
        >.column-2 {
          .make-sm-column(4);
            padding-top: 22px;

          #sm-min({
            padding-top: 72px;
          }); // #sm-min
        }

        >.column-3 {
          .make-sm-column(3);
          .make-sm-column-offset(1);
        }
        >.column-4 {
          .make-sm-column(1);

          >.facebook {
            display: block;
            width: 38px;
            height: 38px;
            margin-top: 75px;
            margin-bottom: 12px;
            background: url(../Images/facebook-footer.svg) no-repeat;
          }

          >.twitter {
            display: block;
            width: 38px;
            height: 38px;
            background: url(../Images/twitter-footer.svg) no-repeat;
          }
        }

      }// >.columns

    }// >.inner-wrap
  }// >.footer-part-1

  >.footer-part-2 {
    height: auto;
    color: #fff;
    background: @brand-primary;

    #sm-min({
      height: 60px;
    });

    >.inner-wrap {
      .container;

      >.schauenburg-logo {
        margin-top: 15px;

        width: 300px;
        height: 20px;
        background-image: url(../Images/schauenburg-logo-01.svg);
        background-repeat: no-repeat;

        #sm-min({
          float: left;
          width: 360px;
          height: 30px;
        });

        #md-min({
          float: left;
          width: 441px;
          height: 30px;
        });
      }

      >.copyright {
        margin-top: 10px;
        margin-bottom: 15px;

        text-transform: uppercase;
        font-size: 10px;
        font-weight: 300;

        #sm-min({
          float: right;
          margin-top: 20px;
          margin-bottom: 0;
          font-size: 12px;
        });

        #md-min({
          margin-top: 19px;
          font-size: 14px;
        });

      }// >.copyright

    }// >.inner-wrap
  }// >.footer-part-2
}// .footer
