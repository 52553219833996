.news-item {
  position: relative;
  margin-bottom: 15px;
  background: #dcdcdc;

  >.inner-wrap {
    position: relative;
    min-height: 170px;
    overflow: hidden;

    >.date {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      z-index: 1;
      padding: 8px 16px;
      font-size: 12px;
      text-transform: uppercase;
      color: #fff;
      background: @brand-primary;
    }// >.date

    >.text {
      position: relative;
      padding: 30px;

      #sm-min({
        margin-right: 320px;
      });

      >a {
        font-weight: 600;
      }
    }// >.text

    >.image {
      width: 100%;
      height: auto;

      #sm-min({
        position: absolute;
        right: 0;
        top: 0;
        width: 300px;
      });

    }// >.image

  }// >.inner-wrap
}// .news-item
