.header .navigation-language {
  .hidden-xs;
  // display: none;
  position: absolute;
  right: 125px;
  top: 15px;
  padding: 0;
  margin: 0 -6px;
  list-style: none;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;

  >li {
    display: block;
    float: left;
    padding: 0 6px;

    &:first-child {
      border-right: solid 2px @gray;
    }

    &.active a {
      color: @brand-primary;
    }

    > a {
      color: @gray;
      transition: color 200ms;

      &:hover {
        color: @brand-primary;
        text-decoration: none;
      }
    }
  }// >li
}// .header .navigation-language


.header .navigation-language-mobile {
  .visible-xs;
  padding: 30px 0;
  margin: 0;
  list-style: none;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  text-align: center;

  >li {
    display: inline-block;
    padding: 0 6px;

    &:first-child {
      padding: 0 8px 0 6px;
      border-right: solid 1px @white;
    }

    &.active a {
      color: @brand-primary;
    }

    > a {
      color: @white;
      transition: color 200ms;

      &:hover {
        color: @brand-primary;
        text-decoration: none;
      }
    }
  }// >li
}// .header .navigation-language
