#xs(@rules) {
  @media only screen and (max-width: @screen-xs-max) {
    @rules();
  }
}
#sm-min(@rules) {
  @media only screen and (min-width: @screen-sm-min) {
    @rules();
  }
}
#sm(@rules) {
  @media only screen
  and (min-width: @screen-sm-min)
  and (max-width: @screen-sm-max) {
    @rules();
  }
}
#sm-max(@rules) {
  @media only screen and (max-width: @screen-sm-max) {
    @rules();
  }
}

#md-min(@rules) {
  @media only screen and (min-width: @screen-md-min) {
    @rules();
  }
}
#md(@rules) {
  @media only screen
  and (min-width: @screen-md-min)
  and (max-width: @screen-md-max) {
    @rules();
  }
}
#md-max(@rules) {
  @media only screen and (max-width: @screen-md-max) {
    @rules();
  }
}

#lg(@rules) {
  @media only screen and (min-width: @screen-lg-min) {
    @rules();
  }
}
