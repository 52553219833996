.tx-apdproducts >.product-detail {

  .header-image-print {
    display: none;
  }

  // >.header-image {
  //   width: 100%;
  //
  // }
  //
  >.headline {
    // text-transform: uppercase;
    font-weight: 500;

    >b {
      font-weight: 700;
    }

    >.group {
      font-size: 14px;

      >.number {
        font-weight: 700;
      }
    }// >.group

  }// >.headline

  >.properties {
    margin-bottom: 60px;
    line-height: 22px;

    h3 {
      color: #000;
    }

    dd {
      margin-bottom: 22px;
    }

  }// >.properties

  >.subproducts-table-wrap {
    .table-responsive;
    margin-top: 30px;
    //
    #sm({
      width: 720px;
    });
    #md({
      width: 940px;
    });
    #lg({
      width: 1140px;
    });

    >.subproducts-table {
      .table;
      font-size: 11px;
      font-weight: 700;

      >thead {
        >tr {
          background: @gray-light;

          >th {
            border-bottom: none;
            font-weight: 700;
            vertical-align: top;

            &:first-child {
              min-width: 100px;
            }
          }
        }// >tr
      }// >thead

      >tbody {
        >tr {
          &:nth-child(even) {
            background: @gray-lighter;
          }
          &:nth-child(odd) {

          }
        }// >tr
      }// >tbody
    }// >.subproducts-table

  }// >.subproducts-table-wrap

  h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
  }

  .headline-advice {
    color: red;
  }

  .advice {
    margin: 30px 0 0 0;
    padding: 15px;
    background: #fff;
  }

  .headline-additional-products {
    margin-bottom: 30px;
  }

  .additional-products {
    .row;

    #sm({
      width: 720px;
    });
    #md({
      width: 940px;
    });
    #lg({
      width: 1140px;
    });

    >.item {
      .make-sm-column(4);
      .make-md-column(3);

      >.inner-wrap {
        padding: 0;
        // background: white;
        // cursor: pointer;

        >.image {
          >.inner-wrap {
            width: 100%;
            height: 210px;
            background-size: cover;
            background-color: @gray-lighter;
            background-position: center;
          }
        }// >.image

        >.name {
          margin: 15px 0 0 0;


            >a {
            font-size: 20px;
            line-height: 26px;
            color: #000;
            transition: color 200ms;

            &:hover {
              color: @brand-primary;
            }

            #sm({
              min-height: 78px;
            });
            #md-min({
              min-height: 52px;
            });
          }
        }
      }// >.inner-wrap
    }// >.item
  }

}// .tx-apdproducts >.product-detail

@media print {
  .tx-apdproducts >.product-detail {
    >.headline {
      font-size: 500px;
      color: green;
    }
  }// .tx-apdproducts >.product-detail
}
