.content-jobs-list {
  padding-top: 15px;
  padding-bottom: 15px;
  color: #fff;

  #sm-min({ padding-top: 30px; padding-bottom: 60px; });

  .content {
    border-top: solid 6px #fff;

    .jobs {
      .job {
        position: relative;
        padding-top: 30px;
        padding-bottom: 30px;
        .row();
        margin: 0;
        border-bottom: solid 6px #fff;

        .open-button {
          position: absolute;
          right: 7px;
          top: 40px;
          width: 20px;
          height: 20px;
          background: url(../Images/job-angle-down.svg) no-repeat center;
          background-size: contain;
          cursor: pointer;

          #xs({
            display: none;
          });
        }

        .img-wrap {
          .make-sm-column(3);

          #xs({
            margin-bottom: 30px;
            padding: 0;
          });

          #sm-min({
            padding-left: 0;
          });

          img {
            width: 100%;
            height: auto;
          }
        }

        .text-wrap {
          .make-sm-column(9);
          color: #1b398f;

          h3 {
            margin-top: 0;
            font-size: 30px;
            line-height: 35px;
            cursor: pointer;
          }

          .teaser {
            margin-bottom: 30px;
            cursor: pointer;
          }

          .details {
            display: none;
            overflow: hidden;
            color: #000;

            .service-bar {
              position: relative;
              margin-top: 30px;
              line-height: 30px;
              font-size: 15px;
              color: #5a5a5a;

              a {
                color: #5a5a5a;

                &:hover {
                  color: #1b398f;
                }

                #xs({
                  display: block;
                });
              }

              #sm-min({
                display: flex;
                justify-content: space-between;
              });

              .service-bar-contacts {
                a {
                  margin-right: 15px;

                  .icon {
                    position: relative;
                    top: 9px;
                    display: inline-block;
                    height: 30px;
                    width: 20px;
                    margin-right: 3px;
                    background: no-repeat center;
                    background-size: 20px auto;
                  }

                  &.phone {
                    .icon {
                      background-image: url(../Images/job-phone.svg);
                    }
                  }

                  &.email {
                    .icon {
                      background-image: url(../Images/job-mail.svg);
                    }
                  }
                }
              }

              .service-bar-download {
                a {
                  .icon {
                    position: relative;
                    top: 8px;
                    display: inline-block;
                    height: 30px;
                    width: 24px;
                    margin-left: 10px;
                    background: url(../Images/pdf-icon.svg) no-repeat center;
                    background-size: auto 30px;
                  }
                }
              }

            }
          }

        }

        &.open {
          .open-button {
            transform: rotate(180deg);
          }
        }

      }
    }
  }
}