.slider {
  margin-bottom: 64px;

  .carousel {

    >.controls {
      position: absolute;
      display: block;
      bottom: 20px;
      width: 100%;

      >.inner-wrap {
        position: relative;
        text-align: center;

        >.carousel-indicators {
          display: inline-block;
          position: relative;
          top: 18px;
          margin: 0 30px;
          bottom: auto;
          left: auto;
          width: auto;

          #sm-min({
            top: -2px;
          });

          >li {
            background: #fff;
            border: solid 2px #fff;
            width: 12px;
            height: 12px;
            margin: 1px;
            background: transparent;
            .transition(background 300ms);

            &:hover {
              background: fade(#fff, 75%);
            }

            &.active {
              background: #fff;
            }
          }
        }

      }// >.inner-wrap
    }// .controls

    .carousel-inner {
      position: relative;
      .aspect-ratio(1110, 408);
      // min-height: 400px;
      background: #fff;

      .item {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition-property: opacity;
        .transition(opacity 2s);
        overflow: hidden;

        &.active {
          opacity: 1;
        }

        .image {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 100%;
          background-size: cover;
          background-position: top center;
        }

        >.text {
          display: none;
          position: absolute;
          left: 0;
          bottom: 0;
          height: 46px;
          width: 100%;
          background: @gray-dark;

          >.headline {
            padding: 0 30px;
            font-size: 16px;
            font-weight: 500;
            line-height: 46px;
            color: #fff;
          }


        }// >.text
      }// .item


      .active >.inner-wrap >.text >.headline {
        opacity: 1;
      }

    }// .carousel-inner
  }// .carousel

}
