section.newsletter-subscribe {
  position: relative;
  overflow: auto;
  margin: 25px 0 15px 0;


  >.inner-wrap {
    .container;
    padding-top: 15px;
    padding-bottom: 15px;

  }

  #sm-min({
    padding-top: 80px;
    padding-bottom: 80px;
  });

  .content {
/*    .row;*/

    #xs({
      margin: 0 15px;
    });

    h1 {
      margin: 0 0 10px 0;
      font-size: 25px;
      line-height: 35px;
      color: @brand-primary;

      #sm-min({
        font-size: 35px;
        line-height: 45px;
      });
    }




    .text {
/*      font-size: 18px;
      line-height: 25px;

      #sm-min({
      font-size: 20px;
      line-height: 30px;
    });*/
    }


    a {
      color: var(--brand-primary);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .form {
      margin: 0 auto;
      padding: 30px;
      background: #fff;
      max-width: 500px;

      label {
        font-weight: 300;

        a {
          text-decoration: underline;
        }
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          overflow: hidden;
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }

          .field_label {
            display: block;

            #sm-min({
              width: 100px;
              float: left;
            });
          }

          .form_field {
            width: 100%;
            /*            max-width: 300px;*/
            padding: 0 15px;
            border: solid 1px #babbc1;
            background: #fff;
            line-height: 40px;
            font-size: 16px;
            border-radius: 3px;
          }

          input[type=radio] {
            position: relative;
            top: -1px;
          }


          .more-btn {
            padding: 0 15px;
            background: @brand-primary;
            color: #fff;
            appearance: none;
            line-height: 40px;
            text-transform: uppercase;
            font-size: 16px;
            border: none;
            border-radius: 3px;

            &:hover {
              background: darken(@brand-primary, 10%);
            }
          }


          #gender_male {
            margin-left: 15px;
          }

        }
      }
    }

  }
}