.header .mobile-navigation-toggle {
  .visible-xs;
  position: absolute;
  right: 15px;
  top: 20px;
  width: 30px;
  height: 34px;
  margin: 0;
  padding: 0;
  float: none;
  border: none;
  background: none;

  >.line {
    display: block;
    width: 100%;
    height: 4px;
    margin: 5px 0 7px;
    background: @gray-dark;
  }
}// .header .mobile-navigation-toggle
