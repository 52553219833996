.contacts {
  position: relative;
  margin: 50px 0;

  >.headline {
    margin: 0 0 15px 0;
    font-size: 20px;
    font-weight: 500;
  }// >.headline

  >.items {
    .row;

    >.item {
      .make-sm-column(6);
      margin-bottom: 30px;

      >.inner-wrap {
        padding: 17px;
        overflow: hidden;
        background: #fff;

        >.image-wrap {
          position: relative;
          float: left;
          width: 174px;
          height: 174px;
          margin-right: 35px;

          >.image {
            width: 100%;
            height: 100%;
          }// >.image
        }// >.image-wrap

        >.text-wrap {
          float: left;
          padding-top: 20px;

          #md({
            width: 190px;
          });
        }
      }// >.inner-wrap
    }// >.item
  }// >.items
}// .contacts
