.product-pdf {
  // background: red;

  .properties {
    font-size: 11px;
    line-height: 14px;
    page-break-after: always;
  }

  .header-image-print {
    display: block !important;
  }

  .header-image-nonprint {
    display: none;
  }

  h3 {
    color: #000;
    margin: 0;
    font-size: 11px;
    line-height: 14px;
    font-weight: bold;
  }

  dl {
    margin: 0;
  }

  dd {
    padding: 0;
    margin-bottom: 12px !important;
    margin-left: 0 !important;
  }

  .main-wrap {
    margin: 0;
  }

  .pdf-header {
    .logo {
      width: 100mm;
      // height: auto;
    }

  }// .pdf-header


  .pdf-footer {
    // background: blue;
    // height: 200px;

  }// .pdf-footer


  .product-detail {
    .pdf-link {
      display: none;
    }
    .downloads {
      display: none;
    }

    .header-image {
      margin-bottom: 30px;
    }
  }

  .headline-table {
    display: none;
  }

  .subproducts-table {
    width: 100%;
    font-size: 8px;

    thead tr {
      background: #b8b8b8;
    }

    th {
      padding: 3px;
    }
    td {
      padding: 3px;
    }

    tbody tr:nth-child(even) {
      background: #dfdfdf;
    }
  }

}// .product-pdf


.pdf-footer {
  position: relative;
  .address {
    padding-bottom: 10px;
    color: @brand-primary;
    font-size: 9px;
    line-height: 12px;
  }
  .page-number {
    text-align: right;
    font-size: 9px;
    line-height: 15px;
  }
}
