.subpage {
  .container;
  padding-top: 20px;
  padding-bottom: 100px;
  min-height: 500px;

  #sm-min({
    padding-top: 0;
  });

  >.content {
    .make-sm-column(12);
    padding: 0;

      #sm-min({
        margin-top: 90px;
      });

  }// >.content

}
