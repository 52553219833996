.slider-intro {
  background: @gray-dark;

  // #sm-min({
  //   margin-bottom: 64px;
  // });

  .carousel {
    .container-fluid;
    padding: 0;
    // max-width: 1400px;

    >.controls {
      position: absolute;
      display: block;
      bottom: 10px;
      width: 100%;

      #sm-min({
        bottom: 15px;
      });

      >.inner-wrap {
        position: relative;
        text-align: center;

        >.carousel-indicators {
          display: inline-block;
          position: relative;
          top: -2px;
          margin: 0 30px;
          bottom: auto;
          left: auto;
          width: auto;

          >li {
            background: #fff;
            border: solid 2px #fff;
            width: 12px;
            height: 12px;
            margin: 1px;
            background: transparent;
            .transition(background 300ms);

            &:hover {
              background: fade(#fff, 75%);
            }

            &.active {
              background: #fff;
            }
          }
        }

      }// >.inner-wrap
    }// .controls

    .carousel-inner {
      position: relative;
      .aspect-ratio(1600, 670);
      min-height: 400px;
      max-height: 660px;
      background: #fff;

      .item {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition-property: opacity;
        .transition(opacity 2s);
        overflow: hidden;

        &.active {
          opacity: 1;
        }

        .image {
          position: absolute;
          height: 100%;
          width: 100%;
          background-size: cover;
          background-position: top center;
        }

        >.text {
          .container;
          position: relative;
          height: 100%;

          >.inner-wrap {
            position: absolute;
            top: 240px;
            margin-right: 20px;

            #sm-min({
              top: auto;
              bottom: 100px;
            });
            #md-min({
              bottom: 150px;
            });
            #lg({
              bottom: 200px;
            });

            >.headline {
              font-size: 30px;
              line-height: 28px;
              font-weight: 500;
              color: #fff;
              text-shadow: 3.5px 6.062px 20px rgba(7, 8, 9, 0.75);

              #sm-min({
                font-size: 30px;
                line-height: 30px;
              });
              #md-min({
                font-size: 60px;
                line-height: 62px;
              });
            }
            >.subline {
              margin-top: 10px;
              font-size: 20px;
              line-height: 24px;
              color: #fff;
              text-shadow: 3.5px 6.062px 20px rgba(7, 8, 9, 0.75);

              #sm-min({
                margin-top: 0;
                font-size: 22px;
                line-height: 26px;
              });
              #md-min({
                font-size: 32px;
                line-height: 36px;
              });
            }
          }// >.inner-wrap
        }// >.text
      }// .item


      .active >.inner-wrap >.text >.headline {
        opacity: 1;
      }

    }// .carousel-inner
  }// .carousel

}
