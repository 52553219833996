.header-image {
  position: relative;
  width: 100%;
  margin: 30px 0 80px 0;

  .inner-wrap {
    position: relative;
    width: 100%;
    padding: 0;

    >img {
      width: 100%;
      height: auto;
    }
  }

}// .header-image
