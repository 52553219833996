.button-element {
  position: relative;
  margin-top: 30px;

   >a {
     display: inline-block;
     width: auto;
     padding: 15px 20px;
     color: #fff;
     font-size: 14px;
     font-weight: 700;
     text-transform: uppercase;
     background: #bebebe;
     transition: background-color 200ms;

     &:hover {
       background: fade(#000, 40);
     }
   }
}
