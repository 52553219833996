h2 {
  margin: 0 0 30px 0;
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
}

h3 {
  margin: 20px 0 30px 0;
  font-size: 30px;
  // line-height: 40px;
  font-weight: 700;
  color: @brand-primary;
}
