.img-retina(@file-1x; @file-2x; @width-1x; @height-1x; @min-width: 0) {
  @media
  only screen and (min-width: @min-width) {
    background-image: url("@{file-1x}");
  }

  @media
  only screen and (min-width: @min-width) and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-width: @min-width) and (   min--moz-device-pixel-ratio: 2),
  only screen and (min-width: @min-width) and (     -o-min-device-pixel-ratio: 2/1),
  only screen and (min-width: @min-width) and (        min-device-pixel-ratio: 2),
  only screen and (min-width: @min-width) and (                min-resolution: 192dpi),
  only screen and (min-width: @min-width) and (                min-resolution: 2dppx) {
    background-image: url("@{file-2x}");
    background-size: @width-1x @height-1x;
  }
}

.img-retina-max-width(@file-1x; @file-2x; @width-1x; @height-1x; @max-width: 0) {
  @media
  only screen and (max-width: @max-width) {
    background-image: url("@{file-1x}");
  }

  @media
  only screen and (max-width: @max-width) and (-webkit-max-device-pixel-ratio: 2),
  only screen and (max-width: @max-width) and (   max--moz-device-pixel-ratio: 2),
  only screen and (max-width: @max-width) and (     -o-max-device-pixel-ratio: 2/1),
  only screen and (max-width: @max-width) and (        max-device-pixel-ratio: 2),
  only screen and (max-width: @max-width) and (                max-resolution: 192dpi),
  only screen and (max-width: @max-width) and (                max-resolution: 2dppx) {
    background-image: url("@{file-2x}");
    background-size: @width-1x @height-1x;
  }
}
.img-retina-min-width(@file-1x; @file-2x; @width-1x; @height-1x; @min-width: 0) {
  @media
  only screen and (min-width: @min-width) {
    background-image: url("@{file-1x}");
  }

  @media
  only screen and (min-width: @min-width) and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-width: @min-width) and (   min--moz-device-pixel-ratio: 2),
  only screen and (min-width: @min-width) and (     -o-min-device-pixel-ratio: 2/1),
  only screen and (min-width: @min-width) and (        min-device-pixel-ratio: 2),
  only screen and (min-width: @min-width) and (                min-resolution: 192dpi),
  only screen and (min-width: @min-width) and (                min-resolution: 2dppx) {
    background-image: url("@{file-2x}");
    background-size: @width-1x @height-1x;
  }
}

.img-retina-min-max-width(@file-1x; @file-2x; @width-1x; @height-1x; @min-width: 0; @max-width: 0) {
  @media
  only screen and (min-width: @min-width) and (max-width: @max-width) {
    background-image: url("@{file-1x}");
  }

  @media
  only screen and (min-width: @min-width) and (max-width: @max-width) and (-webkit-max-device-pixel-ratio: 2),
  only screen and (min-width: @min-width) and (max-width: @max-width) and (   max--moz-device-pixel-ratio: 2),
  only screen and (min-width: @min-width) and (max-width: @max-width) and (     -o-max-device-pixel-ratio: 2/1),
  only screen and (min-width: @min-width) and (max-width: @max-width) and (        max-device-pixel-ratio: 2),
  only screen and (min-width: @min-width) and (max-width: @max-width) and (                max-resolution: 192dpi),
  only screen and (min-width: @min-width) and (max-width: @max-width) and (                max-resolution: 2dppx) {
    background-image: url("@{file-2x}");
    background-size: @width-1x @height-1x;
  }
}

// Aspect ratio
// (Apply to container)
// http://www.mademyday.de/css-height-equals-width-with-pure-css.html
.aspect-ratio(@w, @h) {
  @ratio: (@h / @w);

  &:before {
    content: "";
    display: block;
    padding-top: (100% * @ratio);
  }

  // > * {
  //   .fill;
  // }
}
