.tx-apdproducts >.category >.products {

  >.product {
    margin-bottom: 10px;
    background: #fff;
    cursor: pointer;
    .transition(color 200ms);

    &.no-link {
      cursor: default;

      &:hover {
        color: #000;
      }
    }

    &:hover {
      color: @brand-primary;

      >.inner-wrap >.texts >.name >a {
        color: @brand-primary;
      }
    }

    >.inner-wrap {
      .row;
      padding: 17px;

      >.image {
        .make-sm-column(5);

        >.inner-wrap {
          width: 100%;
          height: 200px;
          background-size: cover;
          background-color: @gray-lighter;
        }
      }
      >.texts {
        .make-sm-column(7);

        >.group {
          margin-top: 10px;
          text-align: right;
          font-size: 14px;
          text-transform: uppercase;

          >.number {
            font-weight: 700;
          }
        }// >.group

        >.name {
          margin: 10px 0 10px 0;
          font-size: 20px;
          font-weight: 500;
          // text-transform: uppercase;

          >b {
            font-weight: 700;
          }

          #sm-min({
            margin-top: 30px;
          });

          >a {
            color: #000;
            .transition(color 200ms);
          }
        }// >.name

        >.description-1 {
          min-height: 42px;
          margin-bottom: 22px;
        }
      }// >.texts

    }// >.inner-wrap
  }// >.product
}// .tx-apdproducts >.category >.products
