section.content-text-with-button {
  position: relative;
  background: #fff;

  >.inner-wrap {
    .container;
    padding-top: 15px;
    padding-bottom: 15px;
    color: @brand-primary;;

  }

  #sm-min({
    padding-top: 30px;
    padding-bottom: 40px;
  });
  #md-min({
    padding-top: 80px;
    padding-bottom: 80px;
  });

  .content {
    .row;

    h1 {
      margin: 0 0 10px 0;
      font-size: 25px;
      line-height: 35px;
      color: @brand-primary;
      text-align: center;

      #sm-min({
      font-size: 35px;
      line-height: 45px;
    });
    }


    h2 {
      margin: 0 0 10px 0;
      font-size: 30px;
      line-height: 40px;
      font-weight: 500;
    }

    h3 {
      margin: 20px 0 10px 0;
      font-size: 25px;
      line-height: 30px;
      font-weight: normal;
/*      font-family: var(--font-family-sans-serif-bold);*/

      &:first-child {
        margin-top: 0;
      }

      #sm-min({
      margin: 30px 0 10px 0;
      font-size: 40px;
      line-height: 36px;
    });
    }

    .text {
      font-size: 18px;
      line-height: 25px;
/*      font-weight: var(--font-weight-light);*/

      #sm-min({
      font-size: 20px;
      line-height: 30px;
    });

      .text-center {
        text-align: center;
      }
    }

    p {
      margin: 0 0 20px 0;

      #sm-min({
      margin: 0 0 30px 0;
    });

      &:last-child {
        margin: 0;
      }
    }

    ul {
      padding-left: 24px;
    }

    /*    a {
          color: var(--brand-primary);
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }*/

    .button-wrap {
      margin-top: 20px;
      text-align: center;
      .btn {
        background: @brand-primary;
        transition: background-color 200ms;
        color: #fff;
        padding: 12px 22px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;

        &:hover {
          background: darken(@brand-primary, 10%);
        }
      }
    }

  }
}