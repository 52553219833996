.product-category-teaser {
  margin-bottom: 30px;

  #sm-min({
    float: left;
    width: 50%;
  });

  &:hover >.inner-wrap >a >.name {
    color: @brand-primary;
  }

  #sm-min({
    &:nth-child(even) {
      padding-right: 15px;
    }
    &:nth-child(odd) {
      padding-left: 15px;
    }
  });

  >.inner-wrap {
    padding: 15px;
    background: white;
    cursor: pointer;

    >.image {
      >.inner-wrap {
        width: 100%;
        height: 210px;
        background-size: cover;
        background-color: @gray-lighter;
        background-position: center;
      }
    }// >.image

    >a >.name {
      margin: 15px 0 0 0;
      font-size: 20px;
      line-height: 26px;
      color: #000;
      transition: color 200ms;

      #sm({
        min-height: 78px;
      });
      #md-min({
        min-height: 52px;
      });
    }

  }
}
