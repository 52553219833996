.subpage-with-navigation {
  .container;
  padding-top: 20px;
  padding-bottom: 100px;
  min-height: 500px;

  #sm-min({
    padding-top: 0;
  });

  >.mobile-navigation-level-2 {
    .visible-xs;
    margin-bottom: 30px;

    >.inner-wrap {
      >.select {
        width: 100%;
      }
    }
  }

  >.content {
    .make-sm-column(7);
    .make-md-column(8);
    padding: 0;

    // #xs({
    //
    // });

    #sm-min({
      margin-top: 90px;
    });

  }// >.content

  >.border {
    .hidden-xs;
    .make-sm-column(5);
    .make-md-column(4);
    padding-left: 50px;
    padding-right: 0;

    #sm-min({
      margin-top: 160px;
    });

    .navigation-level-2 {
      margin: 0;
      padding: 0;
      list-style: none;

      >li {
        >a {
          display: block;
          // height: 34px;
          padding-left: 8px;
          line-height: 34px;
          color: #000;
          border-bottom: solid 1px @gray;
          background-color: transparent;
          .transition(background-color 200ms);

          &:hover {
            background-color: @gray-light;
            color: @brand-primary;
          }
          &.active {
            background-color: @gray-light;
          }
        }// >a
      }// >li
    }// .navigation-level-2

    .navigation-level-3 {
      margin: 0 0 0 0;
      padding: 0;
      list-style: none;

      >li {
        >a {
          display: block;
          // height: 34px;
          padding-left: 20px;
          line-height: 34px;
          color: #3e3e3e;
          border-bottom: solid 1px @gray;
          background-color: transparent;
          .transition(background-color 200ms);

          &:hover {
            text-decoration: underline;
          }
          &.active {
            font-weight: bold;
            text-decoration: underline;
          }
        }// >a
      }// >li
    }// .navigation-level-3

    .navigation-level-4 {
      margin: 0 0 0 0;
      padding: 0;
      list-style: none;

      >li {
        >a {
          display: block;
          // height: 34px;
          padding-left: 40px;
          line-height: 34px;
          color: #676767;
          border-bottom: solid 1px @gray;
          background-color: transparent;
          .transition(background-color 200ms);

          &:hover {
            text-decoration: underline;
          }
          &.active {
            font-weight: bold;
            text-decoration: underline;
          }
        }// >a
      }// >li
    }// .navigation-level-4
  }// >.border
}
