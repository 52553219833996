.header-map {
  position: relative;
  width: 100%;
  height: 600px;
  background: #989898;
  margin-bottom: 64px;

  #sm-min({
    height: 410px;
  });

  >.inner-wrap {

    // Map:
    >.map {
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../Images/map.svg) no-repeat top center;
      background-size: 300px auto;


      #sm-min({
        top: 0;
        background-position: center;
        background-size: contain;
      });

      >.item {
        position: absolute;
        width: 40px;
        height: 54px;
        left: 50%;
/*        background: url(../Images/map-item.svg) no-repeat top center;*/

        .trigger {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 24px;
          z-index: 50;
        }

/*        &.hover {
          >.label {
            top: -30px;
            opacity: 1;
          }
        }*/

        &.france {
          width: 30px;
          height: 46px;
          top: 10px;
          margin-left: -45px;
          background: url(../Images/small-flag.png);
          background-repeat: no-repeat;
          background-size: 30px 46px;
          z-index: 2;

          &.active {
            background-image: url(../Images/small-flag-active.png);
          }

          #sm-min({
            top: 81px;
            margin-left: -69px;
          });
/*          #md({
            top: 76px;
            margin-left: -62px;
          });
          #lg({
            top: 79px;
            margin-left: -61px;
          });*/
        }
        &.germany {
          width: 30px;
          height: 46px;
          background: url(../Images/small-flag-right.png);
          background-repeat: no-repeat;
          background-size: 30px 46px;
          top: -10px;
          margin-left: -7px;
          z-index: 10;

          &.active {
            background-image: url(../Images/small-flag-active-right.png);
          }

          #sm-min({
            top: 69px;
            margin-left: -25px;
          });
/*          #md({
            top: 65px;
            margin-left: -41px;
          });
          #lg({
            top: 64px;
            margin-left: -41px;
          });*/
        }
        &.china {
          width: 30px;
          height: 46px;
          background: url(../Images/small-flag-right.png);
          background-repeat: no-repeat;
          background-size: 30px 46px;
          top: 29px;
          margin-left: 84px;

          &.active {
            background-image: url(../Images/small-flag-active-right.png);
          }

          #sm-min({
            top: 136px;
            margin-left: 200px;
          });

/*          #md({
            top: 129px;
            margin-left: 128px;
          });
          #lg({
            top: 130px;
            margin-left: 126px;
          });*/
        }
        &.canada {
          width: 30px;
          height: 46px;
          background: url(../Images/small-flag-right.png);
          background-repeat: no-repeat;
          background-size: 30px 46px;
          top: -3px;
          margin-left: -112px;

          &.active {
            background-image: url(../Images/small-flag-active-right.png);
          }

          #sm-min({
            top: 57px;
            margin-left: -262px;
          });
/*          #md({
            top: 60px;
            margin-left: -277px;
          });
          #lg({
            top: 55px;
            margin-left: -265px;
          });*/
        }

        &.usa {
          width: 30px;
          height: 46px;
          background: url(../Images/small-flag-right.png);
          background-repeat: no-repeat;
          background-size: 30px 46px;
          top: 23px;
          margin-left: -93px;

          &.active {
            background-image: url(../Images/small-flag-active-right.png);
          }

          #sm-min({
            top: 117px;
            margin-left: -222px;
          });
/*          #md({
            top: 107px;
            margin-left: -243px;
          });
          #lg({
            top: 109px;
            margin-left: -238px;
          });*/
        }

        &.italy {
          width: 30px;
          height: 46px;
          background: url(../Images/small-flag-right.png);
          background-repeat: no-repeat;
          background-size: 30px 46px;
          top: 18px;
          margin-left: -7px;
          z-index: 20;

          &.active {
            background-image: url(../Images/small-flag-active-right.png);
          }

          #sm-min({
            top: 97px;
            margin-left: -18px;
          });
/*          #md({
            top: 129px;
            margin-left: 128px;
          });
          #lg({
            top: 130px;
            margin-left: 126px;
          });*/
        }

        &.romania {
          width: 30px;
          height: 100px;
          background: url(../Images/bigger-flag-right.png);
          background-repeat: no-repeat;
          background-size: 30px 100px;
          top: -38px;
          margin-left: 1px;

          &.active {
            background-image: url(../Images/bigger-flag-right-active.png);
            background-size: 30px 100px;

            #sm-min({
              background-image: url(../Images/big-flag-right-active.png);
              background-size: 30px 78px;
            });
          }

          #sm-min({
            top: 59px;
            margin-left: 9px;
            height: 78px;
            background: url(../Images/big-flag-right.png);
            background-repeat: no-repeat;
            background-size: 30px 78px;
          });
        }

        /*

        >.icon {
          position: absolute;
          left: 6px;
          top: 5px;
          width: 29px;
          height: 28px;
          border-radius: 29px;
          background-color: #fc7d00;
          transition: background 300ms;
          cursor: pointer;
        }

        */
        >.label {
          display: none;
          position: absolute;
          top: -20px;
          left: 40px;
          // z-index: 1;
          padding: 10px;
          font-size: 14px;
          background: fade(#292929, 80);
          opacity: 0;
          transition: opacity 300ms, top 200ms;
        }

        &.active {
          >.icon {
            background-color: #1b398f;
          }
        }

      }// >.item
    }// >.map

    >.details {
      position: absolute;
      bottom: 17px;
      left: 17px;
      right: 17px;
      display: none;

      >.country-name {
        display: inline-block;
        margin-bottom: 4px;
        padding: 20px;
        font-size: 14px;
        color: #fff;
        background: rgba(41,41,41,.7);
      }
      >.columns-wrap {
        padding: 20px;
        font-size: 14px;
        color: #fff;
        background: rgba(41,41,41,.7);

       a {
         color: #fc7d00;

         &:hover {
           text-decoration: underline;
         }
       }

        >.columns {
          .row;

          >.column {
            .make-sm-column(4);
            #xs({
              margin-bottom: 14px;
              &:last-child {
                margin-bottom: 0;
              };
            });
          }

          }// >.columns
      }// >.columns-wrap

    }// >.details

  }// .inner-wrap
}
