.page-flyout {
  display: block;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  color: #fff;
  z-index: 1000;
  width: auto;

  @media (min-width: @screen-md-min) {
    display: block;
  }

  >.inner-wrap {
    height: 180px;
    width: 60px;
  }

  .container {
    position: absolute;
    width: 300px;
    height: 60px;
    background: @brand-primary;
/*    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;*/
    left: 0;
    margin: 0 0 3px 0;
    padding: 0;
    transition: left 250ms;
//    animation: container-slide-in;
//    animation-duration: 500ms;
//    animation-delay: 500ms;
//    animation-fill-mode: forwards;

    &.hover {
      left: -240px;
    }

    &:nth-child(2) {
      top: 63px;
      animation-delay: 700ms;
    }
    &:nth-child(3) {
      top: 126px;
      animation-delay: 900ms;
    }
    &:nth-child(4) {
      top: 189px;
      animation-delay: 1100ms;
    }

    &.mail {
      svg {
        margin: 20px 0 0 16px;
      }

      .content {
/*        padding: 20px 0 20px 30px;*/
/*        font-size: 14px;*/
      }

/*      .content {
        bottom: 0;
        top: auto;
        padding: 11px 30px;
        font-size: 14px;
      }*/
    }

    &.phone {
      svg {
        margin: 11px 0 0 20px;
      }
    }

    &.location {
      svg {
        margin: 14px 0 0 20px;
      }
    }

    &.catalog {
      svg {
        width: 27px;
        height: auto;
        margin: 15px 0 0 18px;
      }
    }

    .button {
      display: block;
      width: 60px;
      height: 60px;
      cursor: pointer;

      svg {
      }
    }
    .content {
      display: block;
      position: absolute;
      left: 60px;
      top: 0;
      width: 240px;
      padding: 20px 20px 20px 10px;
      font-size: 15px;
      line-height: 18px;
      background: @brand-primary;

      a {
        color: #fff;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@keyframes container-slide-in {
  0%   {left: -240px}
  100% {left: 0}
}