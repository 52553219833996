.header .navigation-meta {
  .hidden-xs;
  position: absolute;
  right: 15px;
  top: 60px;
  padding: 0;
  margin: 0;
  list-style: none;
  font-weight: 700;

  >li {
    display: block;
    float: left;
    padding: 0;

    > a {
      color: @gray-darker;
      transition: color 200ms;

      &.active,
      &:hover {
        color: @brand-primary;
        text-decoration: none;
      }

      >.icon {
        display: inline-block;
        width: 24px;
        height: 20px;
        margin-right: 6px;
        background-image: url(../Images/contact-icon.svg);
        background-repeat: no-repeat;
        background-size: 23px 20px;
      } // >.icon

    }// >a
  }// >li

}// .header .navigation-meta
